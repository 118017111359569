import React from "react";
import { IoCalendarClearOutline, IoLocation } from "react-icons/io5";
import Image from "../../../../components/Image";

const Programs = ({ data, hideDate }) => {
  // TODO: Button component, title component, section too?
  return (
    <section id="programs" className=" relative mx-[16px] mt-8 rounded-2xl  pb-20 pt-10  md:mx-24" data-aos="fade-right" data-aos-offset="500">
      <div className="container-px">
        {/* <p dangerouslySetInnerHTML={{ __html: data && data?.programs?.meet_our_coaches }} /> */}
        <div className="mx-auto flex flex-col justify-center gap-4 md:max-w-none md:flex-row md:flex-wrap">
          {data.programs &&
            data.programs.map((program, index) => {
              return (
                <div className="border-muted flex shrink grow basis-1/4 flex-col overflow-hidden rounded-2xl border bg-white md:max-w-80" key={index}>
                  {program.image_path !== null && program.image_name !== null && (
                    <div className="relative">
                      <Image src={program.image_path + "/" + program.image_name} width={300} height={300} className="w-full object-cover" />
                      <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent"></div>
                    </div>
                  )}
                  <div className="relative z-10 px-6 pb-8 pt-2">
                    <h5 className="mt-3 font-bold">{program?.name_en}</h5>
                    <p className="mb-0 font-bold">{program?.center?.name_en}</p>
                    {hideDate && (
                      <p>
                        <strong>{program?.start_date}</strong> till <strong>{program?.end_date}</strong>
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default Programs;
